
const init = () => {
    $('.ita-carousel').each(function() {
        const $this = $(this);
        const $container = $this.closest('.ita-carousel-container');

        const autoplay = $container.data('autoplay');
        const autoplaySpeed = $container.data('autoplay-speed');
        const pauseOnHover = $container.data('pause-on-hover');

        $this.slick({
            autoplay: autoplay,
            autoplaySpeed: autoplaySpeed,
            arrows: false,
            centerMode: true,
            centerPadding: '16px',
            lazyLoad: 'progressive',
            mobileFirst: true,
            pauseOnFocus: false,
            pauseOnHover: pauseOnHover,
            speed: 1200,
            cssEase: 'ease-in-out',
            slidesPerRow: 1,
            slidesToShow: 1,
            useTransform:false,
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    arrows: false,
                    centerPadding: '5%'
                  }
                }
            ]
        });
    });
};

module.exports = {
    init,
    methods: {}
};
